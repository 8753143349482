<template>
  <div>
    <h4 class="page-header"> Bid Bond By Creator</h4>
    <div class="panel panel-inverse">
      <div class="panel-body">
        <div class="row">
          <div class="form-group col-md-3">
            <label class="fs">Start Date</label>
            <date-picker
                v-model="start"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          </div>
          <div class="form-group col-md-3">
            <label class="fs">End Date</label>
            <date-picker
                v-model="to"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          </div>
        </div>
        <div v-if="bidbonds.length" class="row">
          <div class="col-md-12">
            <panel noButton="true" title="Bidbonds By RMs vs Customers">
              <line-chart :chart-data="lineChart" :options="options" class="height-sm"></line-chart>
            </panel>
          </div>
        </div>
        <vue-good-table
            :columns="columns"
            :lineNumbers="true"
            :pagination-options="{ enabled: true,  position: 'bottom' }"
            :rows="bidbonds"
            :search-options="{ enabled: true }"
        >
          <div slot="table-actions">
            <download-excel
                v-if="reports.length"
                :data="reports"
                :title="'Companies Commission'"
                class="btn btn-primary"
                name="companies commission.xls"
            >
              <i class="fa fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';
import {mapGetters} from "vuex";
import LineChart from "@/components/vue-chartjs/LineChart";
import helper from "@/mixins/helper";

const chart_data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  datasets: [{
    label: 'BidBond Value By RM',
    borderColor: '#348fe2',
    pointBackgroundColor: '#348fe2',
    pointRadius: 2,
    borderWidth: 2,
    backgroundColor: 'rgba(52, 143, 226, 0.3)',
    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  }, {
    label: 'BidBond Value By Customer',
    borderColor: '#2d353c',
    pointBackgroundColor: '#2d353c',
    pointRadius: 2,
    borderWidth: 2,
    backgroundColor: 'rgba(45, 53, 60, 0.3)',
    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  }]
};

// };
export default {
  mixins: [helper],
  components: {
    DatePicker,
    LineChart
  },
  data() {
    return {
      columns: [
        {
          label: 'Bidbond Reference',
          field: 'reference'
        }, {
          label: 'Company',
          field: 'company'
        },
        {
          label: 'CounterParty',
          field: 'counter_party'
        },
        {
          label: 'Amount',
          field: 'amount',
          formatFn: this.formatFn
        },
        {
          label: 'CUR',
          field: 'currency'
        },
        {
          label: 'Deal Date',
          field: 'deal_date'
        },
        {
          label: 'Created By',
          field: 'creator'
        },
        {
          label: 'Role',
          field: 'bidbond_creator'
        },
      ],
      start: moment().add('-2', 'months').toDate(),
      to: moment().toDate(),
      lineChart: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: 'nearest',
          intersect: true
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 1000
            }
          }]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      bidbonds: 'getBidsByCreator',
      reports: 'getBidbondsCreatorFormatted',
      by_rm: 'getBidsByRm',
      by_customer: 'getBidsByCustomer'
    }),
    period() {
      return [this.start, this.to].join();
    }
  },
  watch: {
    period() {
      if (this.start && this.to) {
        this.fetch();
      }
    },
    by_rm() {
      this.setRM()
    },
    by_customer() {
      this.setCustomer();
    },
  },
  mounted() {
    this.getBidBonds();
  },
  methods: {
    fetch() {
      this.$store.dispatch('fetchBidsByCreator', {start: this.start, end: this.to});
    },
    getBidBonds() {
      if (!this.bidbonds.length) {
        this.fetch();
      } else {
        this.setGraph();
      }
    },
    setRM() {
      if (this.by_rm.length === 0) return;
      let result = this.by_rm.map(function (x) {
        return parseInt(x, 10);
      });
      if (result.reduce((a, b) => {
        return a + b
      }, 0) === 0) return;
      chart_data.datasets[0].data = this.by_rm;
      this.options.scales.yAxes[0].ticks.max = Math.max(...result);
      this.lineChart = chart_data;
    },
    setCustomer() {
      chart_data.datasets[1].data = this.by_customer;
      this.lineChart = chart_data;
    },
    setGraph() {
      this.setRM();
      this.setCustomer();
    }
  }
}
</script>